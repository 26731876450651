'use client';
import { HeaderSchema } from "@/schema";
import { useEffect, useRef, useState } from "react";
import * as styles from './header.css'
import Link from "next/link";

import { FlexContainer } from "@/uikit/container";
import { usePathname } from "next/navigation";
import { Header_Height, pageBreakpoints } from "@/uikit/variables";
import IconLogo from '../../svg/icons/denysLogo.svg'
import { NavigationMenu } from "./NavigationMenu";
import { ActionButtons } from "./ActionButtons/ActionButtons";
import { MobileMenu } from "./MobileMenu/MobileMenu";

interface HeaderProps {
    data: HeaderSchema;
}


export const Header = ({ data }: HeaderProps) => {
    const [bg, setBg] = useState<'transparent' | 'white'>('transparent');
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const headerEl = useRef<HTMLDivElement>(null);
    const pathname = usePathname();
    const button = data.actionButtons;

    useEffect(() => {
        let headerHeight = Header_Height.default;
        headerHeight = headerEl.current?.offsetHeight || Header_Height.default;
        const handleScroll = () => {
            if (window.scrollY > headerHeight && bg !== 'white') {
                setBg('white');
            } else if (window.scrollY <= headerHeight && bg !== 'transparent') {
                setBg('transparent');
            }
        };

        handleScroll();

        window.addEventListener('scroll', handleScroll);
        const handleWindowResize = () => {
            if (window.innerWidth > pageBreakpoints.xs) {
                setMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [bg]);

    useEffect(() => {
        if (menuOpen) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = '';
        }
    }, [menuOpen]);

    const closeMenu = () => setMenuOpen(false);

    return (
        <div
            className={styles.headerWrapperStyle(({ background: bg, menuOpen }))}
            ref={headerEl}
        >
            <div className={styles.navItemWrapper}>
                <FlexContainer className={styles.menuContainerStyle}>
                    <Link href="/" className={styles.logoWrapperStyle}>
                        <IconLogo className={styles.styledLogo} />
                    </Link>
                </FlexContainer>
                <NavigationMenu data={data} closeMenu={closeMenu} pathname={pathname} menuOpen={menuOpen} />
                <ActionButtons buttons={button} menuOpen={menuOpen} closeMenu={closeMenu} />
            </div>
            <MobileMenu data={data} menuOpen={menuOpen} setMenuOpen={setMenuOpen} button={button} closeMenu={closeMenu} />
        </div>
    )
}
Header.displayName = "Header";