'use client';
import { FooterSchema } from "@/schema";
import IconLogo from '../../svg/icons/denysLogo.svg'
import { FlexContainer } from "@/uikit/container";
import Link from "next/link";

import * as styles from './footer.css';
import { Typography } from "@/uikit/Typography";



export const Footer = (props: FooterSchema) => {
    const buttonHref = '#contact-us'

    const handleLinkClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, href: string = '') => {
        if (href.startsWith("#")) {
            event.preventDefault();
            const targetId = href.substring(1);
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
                const yOffset = -80;
                const y = targetElement.getBoundingClientRect().top + window.scrollY + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            } else {
                window.location.href = href;
            }
        }
    };
    return (
        <footer className={styles.footerContainer}>
            <FlexContainer direction="column" className={styles.contentContainer} gap="45">
                <FlexContainer direction="row" justifyContent="between" alignItems="center" className={styles.navWrapper}>
                    <Link href="/">
                        <IconLogo className={styles.styledLogo} />
                    </Link>

                    <FlexContainer className={styles.hideMobile} gap="45">
                        <Link href='#how-we-do-it'>
                            <span className={styles.menuText}>How we do it</span>
                        </Link>
                        <Link href='#testimonials'>
                            <span className={styles.menuText}>Testimonials</span>
                        </Link>
                        <Link href='#pricing'>
                            <span className={styles.menuText}>Pricing</span>
                        </Link>
                    </FlexContainer>
                    <button
                        className={styles.contactUsBtnStyle}
                        onClick={(event) => handleLinkClick(event, buttonHref)}
                    >
                        <Typography settings={{ mode: "light", tag: "button" }} className={styles.buttonText}>
                            Contact Us
                        </Typography>
                    </button>
                </FlexContainer>
                <FlexContainer className={styles.hideDesctop} gap="45">
                    <Link href='#'>
                        <span className={styles.menuText}>How we do it</span>
                    </Link>
                    <Link href='#'>
                        <span className={styles.menuText}>Testimonials</span>
                    </Link>
                    <Link href='#'>
                        <span className={styles.menuText}>Pricing</span>
                    </Link>
                </FlexContainer>
                <div>
                    <span className={styles.copyright}>©Vodniakov.site 2024. All right reserved</span>
                </div>
            </FlexContainer>
        </footer >
    )
}